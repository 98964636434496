import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "mst-klay.firebaseapp.com",
  projectId: "mst-klay",
  storageBucket: "mst-klay.appspot.com",
  messagingSenderId: "631546681012",
  appId: "1:631546681012:web:d6487c9e72512950c1a9e1",
  measurementId: "G-N5YTF59L7W",
};

const firebaseApp = initializeApp(firebaseConfig);

const storage = getStorage(firebaseApp);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth, storage };

export const SET_CURRENCY = "SET_CURRENCY";

export const setCurrency = (currencyName) => {
  return (dispatch) => {
    let currencyRate = 1;

    dispatch({
      type: SET_CURRENCY,
      payload: { currencyName, currencyRate },
    });
  };
};

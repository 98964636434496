import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  PinterestIcon,
  EmailIcon,
} from "react-share";

const BlogPost = ({ blog }) => {
  function NewlineText(details) {
    return details.text.split("\n").map((str, key) => (
      <p style={{ paddingTop: 10 }} key={key}>
        {str}
        {/* {console.log("this is ", str)} */}
      </p>
    ));
  }

  return (
    <Fragment>
      <div className="blog-details-top">
        <div className="blog-details-img">
          <img alt="" src={blog.image} />
        </div>
        <div className="blog-details-content">
          <div className="blog-meta-2">
            <ul>
              <li>{blog.timestamp}</li>
              <li>
                <i className="fa fa-comments-o" />
              </li>
            </ul>
          </div>
          <h3>{blog.title}</h3>

          <NewlineText text={blog.blogDetails.toString()} />
        </div>
      </div>
      <div className="tag-share">
        <div className="dec-tag">
          <ul>
            {blog.category.map((category, key) => {
              return (
                <li>
                  <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
                    {category}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="blog-share">
          <span>share :</span>
          <div className="share-social">
            <ul>
              <li style={{ marginRight: 10 }}>
                <EmailShareButton url={"https://finbotics.ai/blog/" + blog.id}>
                  <EmailIcon size={30} round={true} />
                </EmailShareButton>
              </li>
              <li style={{ marginRight: 10 }}>
                <WhatsappShareButton
                  url={"https://finbotics.ai/blog/" + blog.id}
                >
                  <WhatsappIcon size={30} round={true} />
                </WhatsappShareButton>
              </li>
              <li style={{ marginRight: 10 }}>
                <FacebookShareButton
                  url={"https://finbotics.ai/blog/" + blog.id}
                >
                  <FacebookIcon size={30} round={true} />
                </FacebookShareButton>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="next-previous-post">
        {parseInt(blog.id) > 1 ? (
          <Link to={`${process.env.PUBLIC_URL}/blog/${blog.id - 1}`}>
            {" "}
            <i className="fa fa-angle-left" /> prev post
          </Link>
        ) : (
          <i className="fa fa-angle-left" style={{ opacity: 0.5 }}>
            {" "}
            prev post{" "}
          </i>
        )}
        {parseInt(blog.id) < 5 && (
          <Link to={`${process.env.PUBLIC_URL}/blog/${parseInt(blog.id) + 1}`}>
            next post <i className="fa fa-angle-right" />
          </Link>
        )}
      </div>
    </Fragment>
  );
};

BlogPost.propTypes = {
  blog: PropTypes.object,
};

export default BlogPost;

import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import userReducer from "./userReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import sampleCartReducer from "./sampleCartReducer";
import blogReducer from "./blogReducer";
import childReducer from "./childReducer";
import filterReducer from "./filterReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  blogData: blogReducer,
  sampleCartData: sampleCartReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  userData: userReducer,
  inputReducer: childReducer,
  priceFilter:filterReducer,
  categoryFilter:filterReducer, 
});

export default rootReducer;

import {
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_START,
  UPDATE_USER,
} from "../actions/userActions";

const initState = {
  loading: false,
  user: null,
  userDetails: [],
  error: null,
};

const userReducer = (state = initState, action) => {
  if (
    action.type === REGISTER_START ||
    action.type === LOGIN_START ||
    action.type === LOGOUT_START
  ) {
    return {
      ...state,
      loading: true,
    };
  }
  if (action.type === REGISTER_SUCCESS || action.type === LOGIN_SUCCESS) {
    return {
      ...state,
      laoding: false,
      user: action.payload,
      userDetails: action.userDetails,
    };
  }
  if (action.type === UPDATE_USER) {
    return {
      ...state,
      loading: true,
      userDetails: action.userDetails,
    };
  }
  if (
    action.type === REGISTER_FAIL ||
    action.type === LOGIN_FAIL ||
    action.type === LOGOUT_FAIL
  ) {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }
  if (action.type === LOGOUT_SUCCESS) {
    return {
      ...state,
      user: null,
      userDetails: null,
    };
  }
  return state;
};

export default userReducer;

import PropTypes from "prop-types";
import React, { useState, useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect, useDispatch, useSelector } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { store } from "./index";
import { fetchProducts } from "./redux/actions/productActions";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "./utils/Firebase";
import { products } from "./utils/ProductsData";
import BlogPost from "./wrappers/blog/BlogPost";
import { fetchBlogs } from "./redux/actions/blogActions";
import { blogs } from "./utils/BlogsData";
import DataEntryForm from "./pages/form/DataEntryForm"

//meeting page
const Meeting = lazy(() => import("./pages/meeting/Meeting"));

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

// shop pages
const ShopGridMainBath = lazy(() => import("./pages/shop/ShopGridMainBath"));
const ShopGridMainM = lazy(() => import("./pages/shop/ShopGridMainM"));
const ShopSearch = lazy(() => import("./pages/shop/ShopSearch"));
const ShopGridMainLR = lazy(() => import("./pages/shop/ShopGridMainLR"));
const ShopGridMainO = lazy(() => import("./pages/shop/ShopGridMainO"));
const ShopGridMainK = lazy(() => import("./pages/shop/ShopGridMainK"));
const ShopGridMainD = lazy(() => import("./pages/shop/ShopGridMainD"));
const ShopGridMainC = lazy(() => import("./pages/shop/ShopGridMainC"));
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGrid = lazy(() => import("./pages/shop/ShopGrid"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));
const Collection = lazy(() => import("./pages/shop/Collection"));

// Finishes Page
const FinishesPage = lazy(() => import("./pages/other/FinishesPage"));
// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = 
lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const YourOrders = lazy(() => import("./pages/other/YourOrders"));

const AdminPage = lazy(() => import("./pages/admin/AdminPage"));
const AllOrders = lazy(() => import("./pages/admin/AllOrders"));
const AllUsers = lazy(() => import("./pages/admin/AllUsers"));
const AllBlogs = lazy(() => import("./pages/admin/AllBlogs"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
  });

  useEffect(() => {
    getData();
    // console.log(process.env);
  }, []);

  // const [fbProducts, setFbProducts] = useState([]);
  //const [bath, setbath] = useState([]);

  let dispatch = useDispatch();

  async function getData() {
    try {
      const products = await getDocs(collection(db, "products"));
      const productsArray = [];
      products.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data(),
        };
        // if(obj.category ){
          // console.log(obj)
          productsArray.push(obj);
        // }
          
        // console.log(obj);
      });
      // setFbProducts(productsArray);
      console.log("products fetched");
      dispatch(fetchProducts(productsArray));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeFashion}
                />

                {/* Homepages */}
                <Route
                  path={process.env.PUBLIC_URL + "/home-fashion"}
                  component={HomeFashion}
                />

                {/* Meeting Page */}
                <Route
                  path={process.env.PUBLIC_URL + "/meeting"}
                  component={Meeting}
                />

                {/* Shop pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard/"}
                  component={ShopGridStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid"}
                  component={ShopGrid}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-search"}
                  component={ShopSearch}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-Main/cat=Bathroom"}
                  component={ShopGridMainBath}
                />
                <Route
                  path={
                    process.env.PUBLIC_URL + "/shop-grid-Main/cat=Living Room"
                  }
                  component={ShopGridMainLR}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-Main/cat=Terrace"}
                  component={ShopGridMainM}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-Main/cat=Kitchen"}
                  component={ShopGridMainK}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-Main/cat=Balcony"}
                  component={ShopGridMainM}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-Main/cat=Driveway"}
                  component={ShopGridMainD}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-Main/cat=Cladding"}
                  component={ShopGridMainC}
                />
                 <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-Main/cat=Others"}
                  component={ShopGridMainO}
                />
                

                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                  component={ShopGridFilter}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                  component={ShopGridTwoColumn}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}
                  component={ShopGridNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                  component={ShopGridFullWidth}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                  component={ShopGridRightSidebar}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/shop-list-standard"}
                  component={ShopListStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-list-full-width"}
                  component={ShopListFullWidth}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-list-two-column"}
                  component={ShopListTwoColumn}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/collection"}
                  //   component={Collection}
                  // />
                  render={(routeProps) => (
                    <Collection
                      {...routeProps}
                      key={routeProps.productsArray}
                    />
                  )}
                />
                {/* Finishes Page */}
                <Route
                  path={process.env.PUBLIC_URL + "/finishes-page"}
                  component={FinishesPage}
                />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                  component={ProductTabLeft}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                  component={ProductTabRight}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                  component={ProductSticky}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-slider/:id"}
                  component={ProductSlider}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                  component={ProductFixedImage}
                />

                {/* Blog pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog-standard"}
                  component={BlogStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                  component={BlogNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                  component={BlogRightSidebar}
                />
                {/* <Route
                  path={process.env.PUBLIC_URL + "/blog-details-standard"}
                  component={BlogDetailsStandard}
                /> */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog/:id"}
                  render={(routeProps) => (
                    <BlogDetailsStandard
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/compare"}
                  component={Compare}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/yourorders"}
                  component={YourOrders}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/admin"}
                  component={AdminPage}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/admin-allorders"}
                  component={AllOrders}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/admin-allusers"}
                  component={AllUsers}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/admin-allblogs"}
                  component={AllBlogs}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/form"}
                  component={AdminPage}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));

import { FETCH_BLOGS_SUCCESS } from "../actions/blogActions";

const initState = {
  blogs: [],
};

const blogReducer = (state = initState, action) => {
  if (action.type === FETCH_BLOGS_SUCCESS) {
    return {
      ...state,
      blogs: action.payload,
    };
  }

  return state;
};

export default blogReducer;

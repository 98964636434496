const initialState = {
    priceFilter: { minPrice: 0, maxPrice: Infinity },
    categoryFilter: [],
    // ...other state properties
  };
  
  const rootReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_PRICE_FILTER":
        return {
          ...state,
          priceFilter: action.payload,
        };
      case "SET_CATEGORY_FILTER":
        return {
          ...state,
          categoryFilter: action.payload,
        };
      // ...other cases
      default:
        return state;
    }
  };
  
  export default rootReducer;
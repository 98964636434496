export const ADD_SAMPLE_TO_CART = "ADD_SAMPLE_TO_CART";
export const DECREASE_SAMPLE_QUANTITY = "DECREASE_SAMPLE_QUANTITY";
export const DELETE_SAMPLE_FROM_CART = "DELETE_SAMPLE_FROM_CART";
export const DELETE_ALL_SAMPLES_FROM_CART = "DELETE_ALL_SAMPLES_FROM_CART";

//add to cart
export const addSampleToCart = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductSize
) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Added To Cart", { appearance: "success", autoDismiss: true });
    }
    dispatch({
      type: ADD_SAMPLE_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
          ? item.selectedProductColor
          : null,
        selectedProductSize: selectedProductSize
          ? selectedProductSize
          : item.selectedProductSize
          ? item.selectedProductSize
          : null,
      },
    });
  };
};
//decrease from cart
export const decreaseSampleQuantity = (item, addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Item Decremented From Cart", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    dispatch({ type: DECREASE_SAMPLE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteSampleFromCart = (item, addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: DELETE_SAMPLE_FROM_CART, payload: item });
  };
};

export const deleteAllSamplesFromCart = (addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    dispatch({ type: DELETE_ALL_SAMPLES_FROM_CART });
  };
};

export const sampleCartItemStock = (item, color, size) => {
  if (item.sampleStock) {
    return item.sampleStock;
  } else {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].sampleStock;
  }
};

const initialState = "500"
  
  const childReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_TODO":
        console.log("ADD_TODO called");
        return [...state, action.payload];
      // ... other cases
      case "DELETE_TODO":
        return state.filter((todo) => todo.id !== action.payload);

      case "SET_INPUT_VAL2":
        console.log("SET_INPUT_VAL2 called");
        return [action.payload];
      default:
        return state;
    }
  };
  
  
  export default childReducer;
import { auth, db } from "../../utils/Firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { addDoc, collection, getDocs } from "firebase/firestore";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const UPDATE_USER = "UPDATE_USER";

const registerStart = () => ({
  type: REGISTER_START,
});

const registerSuccess = (user, userDetails) => ({
  type: REGISTER_SUCCESS,
  payload: user,
  userDetails: userDetails,
});

const registerError = (error) => ({
  type: REGISTER_FAIL,
  payload: error,
});

const loginStart = () => ({
  type: LOGIN_START,
});

const loginSuccess = (user, userDetails) => ({
  type: LOGIN_SUCCESS,
  payload: user,
  userDetails: userDetails,
});

const loginError = (error) => ({
  type: LOGIN_FAIL,
  payload: error,
});

const logoutStart = () => ({
  type: LOGOUT_START,
});

const logoutSuccess = (user) => ({
  type: LOGOUT_SUCCESS,
  payload: user,
});

const logoutError = (error) => ({
  type: LOGOUT_FAIL,
  payload: error,
});

const updateUser = (userDetails) => ({
  type: UPDATE_USER,
  userDetails: userDetails,
});

const addUserToCollection = async (
  user,
  architect,
  firmName,
  firmAddress,
  arcImage
) => {
  const userDetails = {
    architect: architect,
    sample: 0,
    firmName: firmName,
    firmAddress: firmAddress,
    arcImage: arcImage,
  };
  await addDoc(collection(db, "users"), {
    uid: user.uid,
    email: user.email,
    userDetails: userDetails,
  });
};

export const registerInitiate = (
  email,
  password,
  architect,
  firmName,
  firmAddress,
  arcImage
) => {
  const userDetails = {
    architect: architect,
    sample: 0,
  };
  return function(dispatch) {
    dispatch(registerStart());
    createUserWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        dispatch(registerSuccess(user, userDetails));
        addUserToCollection(user, architect, firmName, firmAddress, arcImage);
      })
      .catch((error) => {
        dispatch(registerError(error.message));
        alert(error.message);
      });
  };
};

export const loginInitiate = (email, password, userDetails) => {
  return function(dispatch) {
    dispatch(loginStart());
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        dispatch(loginSuccess(user, userDetails));
      })
      .catch((error) => {
        dispatch(loginError(error.message));
        alert(error.message);
      });
  };
};

export const logoutInitiate = () => {
  return function(dispatch) {
    dispatch(logoutStart());
    signOut(auth)
      .then((res) => dispatch(logoutSuccess()))
      .catch((error) => dispatch(logoutError(error.message)));
  };
};

export const updateUserInitiate = (userDetails) => {
  return function(dispatch) {
    dispatch(updateUser(userDetails));
  };
};
